import ConvertUnit from './ConvertUnit.vue'
import ConvertUnitCreate from './Create.vue'
import ConvertUnitEdit from './Create.vue'
import ConvertUnitShow from './Show.vue'

export default [
    {
        path: '/convert_unit',
        name: 'ConvertUnit',
        component: ConvertUnit
    },
    {
        path: '/convert_unit/create',
        name: 'ConvertUnitCreate',
        component: ConvertUnitCreate
    },
    {
        path: '/convert_unit/edit/:id',
        name: 'ConvertUnitEdit',
        component: ConvertUnitEdit
    },
    {
        path: '/convert_unit/show/:id',
        name: 'ConvertUnitShow',
        component: ConvertUnitShow
    },
]
