<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Convert Unit</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="convertUnitInsert">
              <div class="card-body">
                <div class="form-group" v-if="!edit">
                  <label>Product</label>
                  <model-list-select
                    :list="products"
                    v-model="form.product_id"
                    option-value="id"
                    option-text="name"
                    placeholder="Select Product"
                  >
                  </model-list-select>
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <label>Product Unit From</label>
                      <model-list-select
                        v-if="edit"
                        :isDisabled="true"
                        :list="product_units_data"
                        v-model="form.product_unit_from_id"
                        option-value="id"
                        :custom-text="product_nameAndUnit_name"
                        placeholder="Select Product Unit From"
                      >
                      </model-list-select>
                      <model-list-select
                        v-else
                        :list="product_units_data"
                        v-model="form.product_unit_from_id"
                        option-value="id"
                        :custom-text="product_nameAndUnit_name"
                        placeholder="Select Product Unit From"
                      >
                      </model-list-select>
                      <small
                        class="text-danger"
                        v-if="errors.product_unit_from_id"
                        >{{ errors.product_unit_from_id[0] }}</small
                      >
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Product Unit To</label>
                      <model-list-select
                        v-if="edit"
                        :isDisabled="true"
                        :list="product_units_data"
                        v-model="form.product_unit_to_id"
                        option-value="id"
                        :custom-text="product_nameAndUnit_name"
                        placeholder="Select Product Unit To"
                      >
                      </model-list-select>
                      <model-list-select
                        v-else
                        :list="product_units_data"
                        v-model="form.product_unit_to_id"
                        option-value="id"
                        :custom-text="product_nameAndUnit_name"
                        placeholder="Select Product Unit To"
                      >
                      </model-list-select>
                      <small
                        class="text-danger"
                        v-if="errors.product_unit_to_id"
                        >{{ errors.product_unit_to_id[0] }}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Value</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="form.value"
                  />
                  <small class="text-danger" v-if="errors.value"
                    >{{ errors.value[0] }}
                  </small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";
import MasterData from "../../helpers/master";

export default {
  name: "FormProductUnit",
  extends: MasterData,
  data() {
    return {
      name: "Form Convert Unit",
      products: [],
      form: {
        product_unit_from_id: "",
        product_unit_to_id: "",
        value: "",
        product_id: "",
      },
      product_units_data: [],
      isSubmitting: false,
      errors: {},
      edit: false,
      units: [],
    };
  },
  mounted() {
    this.getData();
    this.getProduct();
    this.getUnit();
  },
  updated() {
    this.getProductUnitParams();
  },
  methods: {
    product_nameAndUnit_name(item) {
      return `${item.product_name} - ${item.unit_name}`;
    },
    getData() {
      let id = this.$route.params.id;
      apiGet("convert_unit/" + id)
        .then((result) => {
          this.form = result.data.data;
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProductUnitParams() {
      axios
        .get(this.$store.state.api + "product_unit", {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            product_id: this.form.product_id,
          },
        })
        .then((result) => {
          this.product_units_data = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    convertUnitInsert() {
      if (this.edit === false) {
        this.isSubmitting = true;
        axios
          .post(this.$store.state.api + "convert_unit/store", this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success("Your Convert Unit has been created!");
            this.$router.push({ name: "ConvertUnit" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = true;

        let id = this.$route.params.id;
        axios
          .post(
            this.$store.state.api + "convert_unit/update/" + id,
            this.form,
            {
              headers: {
                Authorization: this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.$noty.success("Your Convert Unit has been updated!");
            this.$router.push({ name: "ConvertUnit" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
